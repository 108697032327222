@import '../../../../theme/breakpoints';

.breadcrumb-nav {
  align-items: center;
  display: flex;
  margin-top: var(--space-sem-margin-medium);
  overflow-x: auto;
  white-space: nowrap;

  @media #{$small} {
    max-width: var(--wrapper-xs);
  }

  @media #{$medium} {
    max-width: unset;
  }

  @media #{$large} {
    margin: 0;
  }

  svg {
    flex-shrink: 0;
    margin-right: var(--size-ref-0);
  }

  ul {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-sem-gap-none);
    gap: 0;
    line-height: var(--text-typeset-line-heights-l);
    list-style-type: none;
    margin: 0 0 5px;
    padding: 0;

    .logo-item {
      align-items: center;
      display: flex;
    }

    .list-item {
      align-items: center;
      display: flex;
      margin: 0;
      padding: 0;

      :first-of-type {
        margin-right: var(--size-ref-0);
      }

      :last-of-type {
        svg {
          display: none;
        }
      }

      a {
        color: var(--color-sem-text-neutral-default);
        text-transform: capitalize;

        :not(:first-of-type) {
          :hover {
            text-decoration: var(--text-typeset-text-decoration-underline);
          }
        }
      }
    }
  }
}
