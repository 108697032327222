@import '../../../../theme/breakpoints';

.cover-wrapper {
  display: grid;
  position: relative;

  img {
    height: 100%;
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
}

.teaser {
  display: flex;
  flex-direction: column;
  gap: var(--space-ref-3);
  padding: 0;
  position: relative;
}

.teaser-primary {
  line-height: 120%;

  &-small {
    h2,
    h3 {
      font-size: var(--text-typeset-font-size-sem-teaser-s);
    }
  }

  &-medium {
    line-height: 170%;

    h2,
    h3 {
      font-size: var(--text-typeset-font-size-sem-teaser-m);
    }
  }

  &-large {
    line-height: 170%;

    h2,
    h3 {
      font-size: var(--text-typeset-font-size-sem-teaser-l);
    }
  }
}

.teaser-secondary {
  display: grid;
  flex-direction: row-reverse;
  grid-template-columns: 1fr 1fr;
  justify-content: center;

  > :first-child {
    order: 2;
  }

  .heading {
    color: var(--color-sem-text-neutral-bold);
    font-size: var(--text-typeset-font-size-xs);
    font-style: normal;
    font-weight: var(--text-typeset-font-weights-normal-700);
    line-height: normal;
  }

  @media #{$large} {
    gap: var(--space-ref-5);
  }
}

.teaser-opinion {
  .heading {
    color: var(--color-sem-text-neutral-subdue);
    font-size: var(--text-typeset-font-size-l);
    font-style: italic;
    font-weight: var(--text-typeset-font-weights-normal-400);
    letter-spacing: var(--text-typeset-letter-spacing-5x-dense);
    line-height: var(--text-typeset-line-heights-xs);
    margin: 0;
    position: relative;
  }
}

.teaser-link {
  align-items: center;
  justify-content: center;
  text-decoration: none;
  z-index: 1;

  &::before {
    bottom: 0;
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  :first-child {
    display: inline;
  }

  @media #{$medium} {
    &::before {
      bottom: 6%;
    }
  }

  @media #{$large} {
    &::before {
      bottom: 10%;
    }
  }

  .heading {
    margin: 0;
    text-transform: none;
    word-break: break-word;
  }

  &:hover,
  &:active,
  &:visited {
    .heading {
      text-decoration: underline;
      text-decoration-thickness: 0.1em;
      text-underline-offset: 5px;
    }
  }

  &:hover .heading {
    text-decoration-color: var(--color-sem-text-primary-default);
  }

  &:active .heading {
    text-decoration-color: var(--color-sem-text-primary-default);
  }

  &:visited .heading {
    text-decoration-color: var(--color-sem-text-primary-default);
  }

  &:focus-visible {
    outline: var(--border-width-sem-thinner) solid var(--color-sem-focus-default);
    outline-offset: var(--space-sem-padding-2xsmall);
  }
}

.placeholder-image {
  align-items: center;
  background: var(--color-sem-surface-neutral-subdue) no-repeat center;
  display: flex;
  justify-content: center;
}

.placeholder-aspect-ratio-2-3 {
  aspect-ratio: 2 / 3;
}

.placeholder-aspect-ratio-3-2 {
  aspect-ratio: 3 / 2;
}

.placeholder-aspect-ratio-16-9 {
  aspect-ratio: 16 / 9;
}

.placeholder-aspect-ratio-67-100 {
  aspect-ratio: 67 / 100;
}

.scale-image-cover {
  background-size: cover;
}

.scale-image-contain {
  background-size: contain;
}

.scale-image-initial {
  background-size: initial;
}

.additional-content {
  a {
    color: var(--color-sem-link-neutral-default);
    font-size: var(--text-typeset-font-size-s);
    font-weight: var(--typography-body-md-600-font-weight);
    line-height: var(--typography-body-md-600-line-height);
    text-decoration: none;
    text-transform: uppercase;
    @media #{$large} {
      line-height: var(--typography-body-lg-600-line-height);
    }
  }
}

.advertisment-label {
  font-weight: var(--text-typeset-font-weights-normal-300);
  line-height: var(--text-typeset-line-heights-l);
  padding-right: var(--space-ref-4);
}
