@import '../../../../theme//breakpoints.scss';

.wrapper {
  display: grid;
  grid-auto-flow: column;
  max-width: var(--wrapper-xl);
  width: 100%;

  @media #{$small} {
    max-width: 100%;
  }
}

.inner-wrapper {
  display: grid;
  grid-auto-rows: min-content;
  width: 100%;

  @media #{$large} {
    margin: 24px 0 5px;
  }

  @media #{$small} {
    article > * {
      max-width: inherit;
    }
  }
}

.below-article-commercial-box {
  max-width: 100%;
  overflow: hidden;
}

.h-line {
  border: 0.6px solid var(--color-sem-border-neutral-default);
  display: none;
  margin: 24px 22px 5px;

  @media #{$large} {
    display: block;
    height: inherit;
  }
}

.right-aside-wrapper {
  align-items: center;
  display: none;
  justify-content: center;
  margin: 24px 0 5px;

  @media #{$large} {
    display: block;
    max-height: inherit;
    min-width: 300px;
    width: 300px;
  }
}
