@import '../../../../../theme/breakpoints';

.placeholder-image {
  align-items: center;
  aspect-ratio: 3 / 2;
  background: var(--color-sem-surface-neutral-subdue) no-repeat center;
  display: flex;
  justify-content: center;
}

.placeholder-aspect-ratio-primary-large {
  @media #{$large} {
    aspect-ratio: 2 / 1;
  }
}

.placeholder-aspect-ratio-wob {
  aspect-ratio: 1 / 1;

  @media #{$medium} {
    aspect-ratio: 3 / 2;
  }

  @media #{$large} {
    aspect-ratio: 9 / 4;
  }
}

.placeholder-aspect-ratio-67-100 {
  aspect-ratio: 67 / 100;
}
