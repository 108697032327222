.indicators {
  bottom: var(--space-ref-3);
  position: absolute;
}

.media {
  right: var(--space-ref-3);
}

.live {
  left: var(--space-ref-3);
}
